@import "react-slideshow-image/dist/styles.css";

body {
  background-color: #515050;
  margin: 0;
  font-family: Arial, sans-serif;
}



.logo {
  height: 150px;
  width: 150px;
}

.book_button {
  position: relative;
  font-size: 25px;
  border-radius: 5vw;
  background-color: white;
  color: #000000;
  padding: 0.5vw 1vw;
  margin: 2vw auto;
  display: block;
  text-align: center;
  text-decoration: none;
}

.book_button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.mission_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-image: url("../images/opacity_3.jpg");
  background-color: rgba(255, 255, 255, 0.5);
  padding: 2vw;
}

.name {
  font-size: 5vw;
  color: #cccccc;
  margin: 1vw 0;
}

.mission_statement {
  font-size: 8vw;
  color: #ffffff;
  margin: 1vw 0;
}

.premier {
  font-size: 5vw;
  color: #cccccc;
  margin: 1vw 0;
}

.services_container {
  padding: 2vw 4vw;
  background-color: #f0f0f0;
}

/* .services {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vw;
  padding: 1vw;
} */

.services li {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2vw;
  outline: 2px solid #ccc;
  border-radius: 15px;
  color: #333;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.services li:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service_header {
  font-size: 48px;
  font-weight: bold;
  color: #333;
  margin-bottom: 1vw;
}

.service_description {
  font-size: 24px;
  color: #666;
  margin-top: 1vw;
  margin-left: 2vw;
  margin-right: 2vw;
}

.services img {
  width: 70%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1vw;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .services {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 992px) {
  .services {
    grid-template-columns: 1fr;
  }

  .services li {
    padding: 1.5vw;
  }

  .service_header {
    font-size: 22px;
  }

  .service_description {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .logo {
    height: 100px;
    width: 100px;
  }

  .name {
    font-size: 7vw;
  }

  .mission_statement {
    font-size: 10vw;
  }

  .premier {
    font-size: 6vw;
  }

  .book_button {
    font-size: 18px;
    padding: 0.5vw 2vw;
    margin: 5vw auto;
  }

  .services {
    padding: 2vw;
  }
}

@media (max-width: 576px) {
  .name {
    font-size: 8vw;
  }

  .mission_statement {
    font-size: 12vw;
  }

  .premier {
    font-size: 7vw;
  }

  .service_header {
    font-size: 20px;
  }

  .service_description {
    font-size: 12px;
  }

  .book_button {
    font-size: 16px;
    padding: 1vw 2vw;
    margin: 6vw auto;
  }
}

.main_footer {
  background-color: #303030;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
}

.footer_message {
  color: white;
  font-size: 2vw;
}

@media (max-width: 768px) {
  .footer_message {
    font-size: 4vw;
  }
}

/* Form */
form {
  display: flex;
  color: #000000;
}
