/* Admin.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ebebeb;
}

.admin-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-title {
  text-align: center;
  margin-bottom: 20px;
}

.admin-table {
  width: 100%;
  max-width: 1000px;  /* Optional: Limit the maximum width of the table */
  border-collapse: collapse;
  margin-bottom: 20px;
}

.admin-table-header {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.admin-table-data {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.admin-table-row:nth-child(even) {
  background-color: #848484;
}

.admin-table-row:nth-child(odd) {
  background-color: #cdcdcd;
}

.wrap-text {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.admin-action-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.admin-action-btn:hover {
  background-color: #d32f2f;
}

@media (max-width: 768px) {
  .admin-table,
  .admin-table thead,
  .admin-table tbody,
  .admin-table th,
  .admin-table td,
  .admin-table tr {
    display: block;
  }

  .admin-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .admin-table tr {
    margin: 0 0 20px 0;
  }

  .admin-table-row:nth-child(even),
  .admin-table-row:nth-child(odd) {
    background: none;
  }

  .admin-table td {
    border: none;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%;
    text-align: right;
  }

  .admin-table td:before {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    transform: translateY(-50%);
    font-weight: bold;
  }

  .admin-table td:nth-of-type(1):before { content: "Customer Name"; }
  .admin-table td:nth-of-type(2):before { content: "Phone"; }
  .admin-table td:nth-of-type(3):before { content: "Email"; }
  .admin-table td:nth-of-type(4):before { content: "Car Make"; }
  .admin-table td:nth-of-type(5):before { content: "Car Model"; }
  .admin-table td:nth-of-type(6):before { content: "Year"; }
  .admin-table td:nth-of-type(7):before { content: "Service"; }
  .admin-table td:nth-of-type(8):before { content: "Additional Comments"; }
  .admin-table td:nth-of-type(9):before { content: "Actions"; }

  .admin-action-btn {
    width: 100%;
    box-sizing: border-box;
  }
}
